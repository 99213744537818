import style from "../style/SensorInfo.module.scss";
export function SensorInfo({ data }: any) {
  return (
    <div className={style.ctnSensorInfo}>
      <div className={style.tex}>{data[0]}</div>
      <div className={`${style.text} ${style.border_left}`}>{data[1]}</div>
      <div className={`${style.text} ${style.border_left}`}>{data[2]}</div>
      <div className={`${style.text} ${style.border_left}`}>{data[3]}</div>
    </div>
  );
}
