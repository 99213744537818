import UserVehicleView from "components/user/components/UserVehicleView";
import React, { FC, memo, useState } from "react";
import { IDevice } from "redux/admin/devices/DevicesTypes";
import {
  DeviceEl,
  DeviceHeader,
  DeviceHeaderCol,
  DeviceBody,
  DevicePhone,
  DeviceCode,
  DeviceFooter,
  DeviceArchived,
  LinkCarInfo,
} from "./Device.styled";
import { ReactComponent as Arrow } from "assets/images/arrow.svg";
import { ArrowEl } from "components/folders/folder/Folder.styled";
import UserVehicleWrapper from "components/user/components/UserVehicleWrapper";
import UserVehicleButtons from "components/user/components/UserVehicleButtons";
import Ping from "../ping/Ping";

interface IDeviceComponent {
  device: IDevice;
  handleOpenModalRemoveDevice: (id: number) => void;
  handleOpenModalEdit: (data: IDevice) => void;
}

const Device: FC<IDeviceComponent> = ({
  device,
  handleOpenModalEdit,
  handleOpenModalRemoveDevice,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleToggle = async () => {
    setOpen(!isOpen);
  };

  return (
    <DeviceEl type={device.archived}>
      <DeviceArchived type={device.archived} />
      <DeviceHeader>
        <DeviceHeaderCol>
          <span>Car_Id:</span> {device.vehicle?.id}
        </DeviceHeaderCol>
        <DeviceHeaderCol>
          <span>Device_id:</span> {device.device_id}
        </DeviceHeaderCol>
      </DeviceHeader>
      <DeviceBody>
        <DevicePhone>{device.phone}</DevicePhone>
        <DeviceCode>{device.code}</DeviceCode>
      </DeviceBody>
      {!device.archived && (
        <DeviceFooter>
          <button
            onClick={(e) => handleOpenModalRemoveDevice(device.id)}
            className="btn a-device__btn a-device-btn-delete"
          >
            Удалить
          </button>
          <button
            onClick={(e) => handleOpenModalEdit(device)}
            className="btn a-device__btn a-device-btn-edit"
          >
            Редактировать
          </button>
        </DeviceFooter>
      )}

      <div style={{ padding: "10px" }}>
        <UserVehicleWrapper
          leftPart={device.vehicle && <UserVehicleView car={device.vehicle} />}
          rightPart={
            device.user && (
              <div>
                <strong>[{device.user.id}]</strong> {device.user.name}
                {device.vehicle && (
                  <LinkCarInfo
                    href={`/view/${device.vehicle.id}`}
                    style={{ marginBottom: "1rem", marginLeft: "1rem", fontSize: "13px" }}
                    target="_blank"
                  >
                    Посмотреть
                  </LinkCarInfo>
                )}
              </div>
            )
          }
          bottomPart={
            device.vehicle && device.user ? (
              <UserVehicleButtons car={device.vehicle} userId={device.user?.id} />
            ) : (
              <div className="userVehicles__message">Транспортные средства не привязаны</div>
            )
          }
        />
        <div>
          <button
            className="button button--mint button--toggle"
            style={{ margin: "10px 0" }}
            onClick={() => handleToggle()}
          >
            Пинг
            <ArrowEl active={isOpen}>
              <Arrow />
            </ArrowEl>
          </button>
          {isOpen && <Ping isScroll={true} deviceFilter={device.device_id.toString()} />}
        </div>
      </div>
    </DeviceEl>
  );
};

export default memo(Device);
