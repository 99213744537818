import { Dispatch } from "redux";
import {
  CarsActions,
  CarsActionTypes,
  CarsUpdateAddressPayload,
  CarsUpdateFieldPayload,
} from "./cars_types";
import api from "../../api";
import { RequestForAccessType } from "redux/car/car_types";
import debounce from "lodash/debounce";

const debounceGetCars = debounce(async (dispatch: Dispatch<CarsActions>) => {
  try {
    dispatch({ type: CarsActionTypes.CARS_LOADING, payload: true });

    const response = await api.getCars();
    dispatch({
      type: CarsActionTypes.GET_CARS,
      payload: response.vehicles,
    });
    dispatch({ type: CarsActionTypes.CARS_LOADING, payload: false });
  } catch (e: any) {
    console.error(e);
  }
}, 400);

export const getCars = () => {
  return async (dispatch: Dispatch<CarsActions>) => {
    debounceGetCars(dispatch);
  };
};

export const carsUpdateAddress = (payload: CarsUpdateAddressPayload) => {
  return async (dispatch: Dispatch<CarsActions>) => {
    dispatch({ type: CarsActionTypes.CARS_UPDATE_ADDRESS, payload });
  };
};

export const changeFilter = (payload: string) => {
  return (dispatch: Dispatch<CarsActions>) => {
    dispatch({ type: CarsActionTypes.CARS_FILTER, payload });
  };
};

export const resetCars = () => {
  return (dispatch: Dispatch<CarsActions>) => {
    dispatch({ type: CarsActionTypes.CARS_RESET });
  };
};

export const carsUpdateLastPoint = (carId: number, deviceId: number) => {
  return async (dispatch: Dispatch<CarsActions>) => {
    const response = await api.getLastPoint(deviceId);

    if (!response?.data?.point) return;

    const payload = {
      id: carId,
      field: "last_point",
      value: response.data.point,
    } as CarsUpdateFieldPayload;

    dispatch({ type: CarsActionTypes.CARS_UPDATE_FIELD, payload });
  };
};

export const postAccessCarsToUser = (data: RequestForAccessType) => {
  return async (dispatch: Dispatch<CarsActions>) => {
    try {
      dispatch({ type: CarsActionTypes.CARS_LOADING, payload: true });
      await api.postUserCarsAccess(data);
      dispatch({ type: CarsActionTypes.CARS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: CarsActionTypes.MESSAGE_ERROR_CARS, payload: (error as Error).message });
      console.error(error);
    }
  };
};

export const postVisibleCarsToUser = () => {
  return async (dispatch: Dispatch<CarsActions>) => {
    try {
      dispatch({ type: CarsActionTypes.CARS_LOADING, payload: true });
      const response = await api.postVisibleCarsToUser();
      dispatch({ type: CarsActionTypes.CARS_LOADING, payload: false });
      dispatch({ type: CarsActionTypes.GET_CARS_PROVIDERS_USER, payload: response.data });
    } catch (error) {
      console.error(error);
    }
  };
};
