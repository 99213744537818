import moment from "moment";
import style from "../style/CardInfo.module.scss";
import { SensorInfo } from "./SensorInfo";
import GreenCircle from "assets/svgElements/GreenCircle";
import RedCircle from "assets/svgElements/RedCircle";

export default function CardInfo({ data, isCircle }: any) {
  const date = moment(data?.created_at);
  const time = date.format("HH:mm:ss");

  const isNew = moment().diff(date, "seconds") < 5;
  return (
    <div className={!isCircle ? style.content : `${style.content} ${style.active}`}>
      <div className={style.header}>
        <div className={style.contentDevId}>
          Время: &nbsp;
          <strong>{time}</strong>
        </div>

        {isCircle ? <GreenCircle /> : <RedCircle />}
      </div>
      <div className={style.ctnInfo}>
        <div className={style.section}>
          <div className={style.itemInfo}>
            Device_ID:
            <div className={style.strongTittle}>{data.device_id}</div>
          </div>
        </div>
        <div className={style.section}>
          <div className={`${style.itemInfo} ${style.itemCode}`}>
            Код:
            <div className={style.strongTittle}> {data.code}</div>
          </div>
          <div className={style.itemInfo}>
            Дата : Время с терминала:
            <div className={style.strongTittle}>{data.date_terminal}</div>
          </div>
        </div>
        <div className={style.section}>
          <div className={style.itemInfo}>
            Зажигание:
            <div className={style.strongTittle}>{data.ignition}</div>
          </div>
          <div className={style.itemInfo}>
            Активность девайса:
            <div className={style.strongTittle}> {data.active_device}</div>
          </div>
        </div>
        <div className={style.section}>
          <div className={style.itemInfo}>
            <div>Координаты:</div>
            Lat:<div className={style.strongTittle}>{data.lat}</div>
            Long:<div className={style.strongTittle}> {data.long}</div>
          </div>
        </div>
        <div className={style.section}>
          <div className={style.itemInfo}>
            Пробег:
            <div className={style.strongTittle}> {data.run}</div>
          </div>
          <div className={style.itemInfo}>
            Скорость:
            <div className={style.strongTittle}>{data.speed}</div>
          </div>
        </div>
        <div className={style.section}>
          <div className={style.itemInfo}>
            Напряжение:
            <div className={style.strongTittle}>{data.voltage}</div>
          </div>
          <div className={style.itemInfo}>
            Уровень сигнала:
            <div className={style.strongTittle}> {data.signal_level}</div>
          </div>
        </div>
        <div className={style.section}>
          <div className={style.itemInfo}>
            Колличество спутников:
            <div className={style.strongTittle}> {data.count_sputnik}</div>
          </div>
          <div className={style.itemInfo}>
            Версия приложения:
            <div className={style.strongTittle}> {data.version_app}</div>
          </div>
        </div>
      </div>
      <div className={`${style.itemInfo} ${style.border_top}`}>
        HEX:
        <div className={style.strongTittle}> {data.hex}</div>
      </div>
      <div className={`${style.itemInfo} ${style.border_top}`}>
        <div className={style.strongTittle}>Датчики: </div>
        <div className={style.ctnSensors}>
          {data?.sensors?.map((item: any, index: number) => (
            <SensorInfo key={index} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
