import { useSuspenseQuery } from "@tanstack/react-query";
import React from "react";
import { newTerminalApi } from "../api/api";

export default function useDataTerminal() {
  const { data: dataTerminal } = useSuspenseQuery<any>({
    ...newTerminalApi.getTerminalDataListOptions(),
    select: (data) => [...data],
  });

  return { dataTerminal };
}
