import { ItemPackTypes } from "../Ping";
import cls from "./style/MainParams.module.scss";
// import WheelList from "./WheelList";

const MainParams = ({ date, code, ignition, speed, lat, long, mileage, tires }: ItemPackTypes) => {
  return (
    <div className={cls.mainParams}>
      <div className={cls.ctnColumn}>
        <div className={cls.columns}>
          <div className={cls.item}>
            Дата пакета: <strong>{date}</strong>
          </div>
          <div className={cls.item}>
            код: <strong>{code}</strong>
          </div>
        </div>
        <div className={cls.columns}>
          <div className={cls.item}>
            зажигание: <strong>{ignition ? "Вкл" : "Выкл"}</strong>
          </div>
          <div className={cls.item}>
            cкорость:<strong>{speed}</strong>
          </div>
        </div>
        <div className={cls.columns}>
          <div className={cls.item}>
            координаты:
            <strong>
              [{long}, {lat}]
            </strong>
          </div>
          <div className={cls.item}>
            Пробег: <strong>{mileage.toLocaleString()}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainParams;
