import cls from "./style/Wheel.module.scss";

const Wheel = ({ pressure, temperature, index }: WheelTypes) => {
  return (
    <div key={index} className={cls.ctnWheel}>
      <div className={cls.content}>
        <strong>{index + 1}.</strong>
      </div>
      <div className={`${cls.content} ${cls.border_left}`}>{pressure.toFixed(2)} bar</div>
      <div className={`${cls.content} ${cls.border_left}`}>{temperature} °C</div>
    </div>
  );
};

export default Wheel;

interface WheelTypes {
  pressure: number;
  temperature: number;
  index: number;
}
