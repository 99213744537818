import { useEffect, useRef, useState } from "react";
import { IReportCount } from "./eventInterfaces";

const ReportCount = ({ count, title1, title2 }: IReportCount) => {
  const [displayedNumber, setDisplayedNumber] = useState<any>(count);
  const animationRef = useRef();
  useEffect(() => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    const start = displayedNumber;

    const duration = 100;
    const startTime = performance.now();

    const animate = (time: any) => {
      const progress = Math.min((time - startTime) / duration, 1);
      if (count !== undefined) {
        const current = Math.floor(start + (Number(count) - start) * progress);
        setDisplayedNumber(current);
      }
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  }, [count, displayedNumber]);
  const displayValue = isNaN(displayedNumber) ? 0 : displayedNumber;
  const value = displayValue >= 1000 ? displayValue / 1000 : displayValue;
  const formattedValue = value.toLocaleString("ru-RU", {
    minimumFractionDigits: value % 1 === 0 ? 0 : 3,
    maximumFractionDigits: 3,
  });

  return (
    <div className="report_count">
      <p className="report_count-p">
        <span className="report_bold report_blue report_padding">{formattedValue}</span>
        {title1}
      </p>
      <p className="report_count-p">{title2}</p>
    </div>
  );
};

export default ReportCount;
