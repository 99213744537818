import api from "api";
import { useEffect, useState } from "react";
import useDataTerminal from "./hooks/use-data-terminal";
import style from "./style/NewTerminalPage.module.scss";
import CardInfo from "./modules/CardInfo";
import { NoData } from "./modules/NoData";

export function NewTerminalPage() {
  const { dataTerminal }: any = useDataTerminal();
  console.log("dataTerminal", dataTerminal);

  return (
    <div className={style.container}>
      {dataTerminal.length === 0 ? (
        <NoData />
      ) : (
        dataTerminal?.map((data: any) => (
          <CardInfo
            data={data}
            key={data.id}
            isCircle={dataTerminal[0].created_at === data.created_at}
          />
        ))
      )}
    </div>
  );
}
