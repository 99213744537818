import { useState, useEffect, useRef } from "react";
// @ts-ignore
import io from "socket.io-client";
import moment from "moment";
import PingHead from "./components/PingHead";
import ItemList from "./components/ItemList";
import { sockUrl } from "helpers/config";
import CustomScroll from "react-custom-scroll";

const Ping = ({ deviceFilter, isScroll }: { deviceFilter?: string; isScroll?: boolean }) => {
  const socket: any = useRef(null);
  const [items, setItems] = useState<PacketItemTypes[]>([]);
  const [lastItem, setLastItem] = useState<LastItemTypes | null>(null);
  const [isPause, setPause] = useState(false);
  const [showHex, setShowHex] = useState(false);
  const [filter, setFilter] = useState("");
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const itemsRef = useRef(items);
  itemsRef.current = items;

  useEffect(() => {
    connect();
    // @ts-ignore
    if (localStorage.getItem("__filter")) setFilter(localStorage.getItem("__filter"));
    if (localStorage.getItem("__hex")) setShowHex(true);
    if (deviceFilter) setFilter(deviceFilter);

    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current);
        timer.current = null;
      }

      socket.current.removeAllListeners();
      socket.current.disconnect();
    };
  }, []);

  const connect = () => {
    console.log("connecting for ping...");

    if (socket && socket.current) socket.current.disconnect();

    socket.current = io(`${sockUrl}:443`);

    socket.current.on("welcome", (_: any) => {
      const newItems = [];
      newItems.push({
        date: moment().format("HH:mm:ss"),
        text: "Соединение установлено",
      });

      // console.log("itemsRef.current", itemsRef.current);

      itemsRef.current.forEach((item: PacketItemTypes) => {
        if (newItems.length < 20) newItems.push({ ...item });
      });

      setItems(newItems);

      // переподключение через 2 минуты
      timer.current = setTimeout(connect, 2 * 60000);
    });

    socket.current.on("connect_error", (error: any) => {
      console.log("[CONNECT ERROR]", error);
      socket.current.disconnect();
    });

    socket.current.on("error", (error: any) => {
      console.log("[ERROR]", error);
      socket.current.disconnect();
    });

    socket.current.on("ping device", (data: LastItemTypes) => {
      setLastItem(data);
    });
  };

  useEffect(() => {
    if (lastItem && !isPause) {
      if (filter.length === 0 || filter.toString() === lastItem.device.toString())
        updateItems(lastItem);
    }
  }, [lastItem]);

  const updateItems = (item: LastItemTypes) => {
    console.log("data", item);

    if (item.device === 11205) return; /// убрать когда будет готов компонент под новый терминал

    const newItems = [];

    if (item.pack) {
      newItems.push({
        date: moment().format("HH:mm:ss"),
        text: `Сигнал от устройства`,
        id: item.device,
        pack: item.pack,
      });
    } else {
      newItems.push({
        date: moment().format("HH:mm:ss"),
        text: `Сигнал от устройства`,
        id: item.device,
      });
    }

    items.forEach((item) => {
      if (newItems.length < 20) newItems.push({ ...item });
    });

    setItems(newItems);
  };

  const handleHex = () => {
    if (!showHex) {
      localStorage.setItem("__hex", true.toString());
    } else {
      localStorage.removeItem("__hex");
    }

    setShowHex(!showHex);
  };

  return (
    <div className="ping-container">
      <h1>Пингуем устройства</h1>

      <PingHead
        handleHex={handleHex}
        filter={filter}
        setFilter={setFilter}
        showHex={showHex}
        isPause={isPause}
        setPause={setPause}
      />
      {isScroll ? (
        <CustomScroll allowOuterScroll={true} heightRelativeToParent="calc(400px)">
          <ItemList items={items} showHex={showHex} isScroll={isScroll && isScroll} />
        </CustomScroll>
      ) : (
        <ItemList items={items} showHex={showHex} />
      )}
    </div>
  );
};

export default Ping;

export interface PacketItemTypes {
  date: string;
  text: string;
  id?: number;
  pack?: ItemPackTypes;
}

interface LastItemTypes {
  device: number;
  pack?: ItemPackTypes;
}

export interface ItemPackTypes {
  code: string;
  date: string;
  hex: string;
  ignition: boolean;
  lat: number;
  long: number;
  mileage: number;
  speed: number;
  tires: string;
}
