import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import SocketListener from "components/socket/SocketListener";
import { MobileContextProvider } from "context/MobileContext";
import { Toaster } from "react-hot-toast";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api/query-client";

const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Toaster />
        <MobileContextProvider>
          <SocketListener />
          <BrowserRouter>{children}</BrowserRouter>
        </MobileContextProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default Providers;
