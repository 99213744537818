import { queryOptions } from "@tanstack/react-query";
import api from "api";

export type PaginateResult<T> = {
  data: T[];
  first: number;
  items: number;
  last: number;
  next: number | null;
  pages: number;
  prev: number | null;
};

export type DataTerminal = {
  id: number;
  code: string;
  created_at: string;
  date_terminal: string;
  ignition: string;
  lat: string;
  long: string;
  run: string;
  sensors: string[];
  speed: string;
  updated_at: string;
  voltage: string;
};

export const newTerminalApi = {
  baseKey: "newTerminal",

  getTerminalDataListOptions: () => {
    return queryOptions<PaginateResult<DataTerminal[]>>({
      queryKey: [newTerminalApi.baseKey],
      queryFn: () =>
        api
          .getDataAllNewTerminal()
          .then((res) => {
            return res;
          })
          .catch((err) => {
            console.error("API Error:", err);
            throw err;
          }),
      refetchInterval: 2000,
    });
  },
};
