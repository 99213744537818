import Wheel from "./Wheel";

export default function WheelList({ tires }: WheelListTypes) {
  const res = JSON.parse(tires);
  return (
    <>
      {res && res.length > 0 ? (
        <>
          <div className="console__title_item_list">Давление - Температура:</div>
          <div className="console__item-wheels">
            {res.map((item: TiresTypes, index: number) => (
              <Wheel key={index} index={index} {...item} />
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

interface WheelListTypes {
  tires: string;
}

interface TiresTypes {
  pressure: number;
  temperature: number;
}
