import React, { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useAction";
import { ModalsState } from "../../redux/modals/modals_types";
import ModalInput from "../ui/Inputs/ModalInput";
import SkeletonLoading from "../skeleton-loading/SkeletonLoading";
import ModalWindow from "./ModalWindow";
import toast from "react-hot-toast";
import { CarState } from "redux/car/car_types";
import { TyresState } from "redux/tyres/tyres_types";
import { ACTION_TYPES } from "constants/actions";
import SelectInput from "components/select-input/SelectInput";
import ModalTextarea from "components/ui/Textarea/ModalTextarea";
import api from "api";
import moment from "moment";
import CustomDatePicker from "components/datepicker/CustomDatePicker";
import cls from "./style/StyleModal.module.scss";
import imgFile from "../../assets/images/UploadFileIcon/Vector.svg";
import truncateFilename from "./modal-components/funcFileName";
import ValidationDelete from "./modal-components/UploadFile/ValidationDelete";
import delNoActiv from "../../assets/images/UploadFileIcon/delteNoAction.svg";
import { useNavigate } from "react-router-dom";
import { ConstructorState } from "redux/constructor/constructor_types";
import TyrePositionList from "components/constructor/placementTyre/TyrePositionList";

const ModalAddAction = () => {
  const filePicker = useRef<any>(null);
  const [type, setType] = useState<string>("");
  const [runLast, setRunLast] = useState<string>("");
  const [delValidation, setDelValidation] = useState<any>(null);
  const [run, setRun] = useState<string>("");
  const [selectFile, setSelectFile] = useState<any>([]);
  const [tread_start, setTreadStart] = useState<string>("");
  const [tread, setTread] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [readonly, setReadonly] = useState<boolean>(false);
  const [model, setModel] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [actionType, setActionType] = useState<boolean>(false);
  const {
    toggleModal,
    addAction,
    updateTyre,
    closeActionModal,
    closeActionReset,
    carTyrePosition,
    postHistoryTyre,
  } = useActions();
  const [submiting, setSubmiting] = useState<boolean>(false);
  const modals: ModalsState = useTypedSelector((state) => state.modals);
  const constructor: ConstructorState = useTypedSelector((state) => state.design);
  const { modelId, trailerId } = constructor;
  const { car, position_tyres }: CarState = useTypedSelector((state) => state.car);
  const { tyre }: TyresState = useTypedSelector((state) => state.tyres);
  const [production_date, setProdDate] = useState(moment().format("YYYY-MM-DD"));
  const [actionTypes, setActionTypes] = useState<Array<any>>([]);
  const tyreId: any = tyre?.id;
  const navigation = useNavigate();
  const [formRepair, setFormRepair] = useState<any>({
    tyre_id: "",
    type_of_repair: "",
    price: "",
    date: production_date,
    reason: "",
    tread: "",
    run: "",
    comment: "",
    uploaded_file: "",
  });
  const [errors, setErrors] = useState<any>({
    type: [],
    run: [],
    tread: [],
    comment: [],
    reason: [],
    to: [],
    runLast: [],
  });

  useEffect(() => {
    setActionTypes([
      { value: "repair", label: ACTION_TYPES.repair },
      { value: "store", label: ACTION_TYPES.store },
    ]);
  }, []);

  useEffect(() => {
    setReadonly(true);
  }, [tyre]);

  const handleChangeUpload = (e: any) => {
    setSelectFile([...selectFile, e.target.files[0]]);
  };
  const onChangeType = (value: string) => {
    setType(value);
  };
  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormRepair({
      ...formRepair,
      tread: tread,
      run: run,
      tyre_id: tyreId,
      type: "repair",
      date: production_date,
      [name]: value,
    });
  };

  const filterPositions = (carPosition: any, tyrePosition: any) => {
    if (Array.isArray(carPosition) && tyrePosition) {
      const tyres: any = tyrePosition;
      return carPosition.filter((el: any) => el !== tyres[0]);
    }
    return carPosition || [];
  };
  const newTyresPos = filterPositions(car?.position_tyres, tyre?.position_tyre);

  const onDateChoose = (from: string) => {
    setProdDate(from);
  };
  const closeValidDelete = () => {
    setDelValidation(null);
  };
  const clickDelete = (e: any) => {
    setDelValidation(e);
  };

  const handleDelete = (e: any) => {
    setSelectFile(selectFile?.filter((_: any, i: any) => i !== e));
    setDelValidation(null);
  };
  const handlePick = (e: any) => {
    e.preventDefault();
    setDelValidation(null);
    filePicker.current.click();
  };
  const handleClose = () => {
    toggleModal({ toggle: false, modal: "modal_add_action" });
    setFormRepair({
      tyre_id: "",
      type_of_repair: "",
      price: "",
      date: production_date,
      reason: "",
      tread: "",
      run: "",
      comment: "",
      uploaded_file: "",
    });
    setErrors({
      type: [],
      run: [],
      tread: [],
      comment: [],
      reason: [],
      to: [],
      runLast: [],
    });
    setType("");
    setRun("");
    setTread("");
    setReason("");
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    let data;
    const updateTyreData = {
      position_tyre: [],
      // history_vehicle: [{ car_id: car?.id, name: car?.title }],
    };
    const baseData = {
      tyre_id: tyre?.id,
      tread: tread,
      type,
      run: run,
      reason,
      comment,
    };
    if (car && tyre) {
      try {
        if (errors.run.length == 0) {
          switch (type) {
            case "repair":
              const formData = new FormData();
              selectFile.forEach((file: any) => {
                formData.append("uploaded_file[]", file);
              });
              formData.append("tread", formRepair?.tread);
              formData.append("run", run);
              formData.append("type_of_repair", formRepair.type_of_repair);
              formData.append("price", formRepair.price);
              formData.append("date", production_date);
              formData.append("reason", formRepair.reason);
              formData.append("type", "repair");
              formData.append("comment", formRepair.comment);
              formData.append("tyre_id", tyreId);
              addAction(formData, "repair");
              toast.success("Данные успешно добавлены");
              handleClose();
              break;
            case "store":
              data = { ...baseData, vehicle_id: car.id };
              setActionType(true);
              closeActionModal(true);
              addAction(data, type);
              postHistoryTyre({
                tyre_id: tyre?.id,
                vehicle_id: car?.id,
                position_tyre: tyre?.position_tyre,
                vehicle_name: car?.title,
                model_id: modelId,
                trailerId: trailerId,
              });
              carTyrePosition(car?.id, newTyresPos);
              await api.putCar(car?.id, {
                position_tyres: newTyresPos,
              });
              if (car?.id !== undefined || actionType === true) {
                updateTyre(tyre?.id, updateTyreData);
              } else {
                console.log("Car ID is undefined");
              }
              closeActionReset();
              navigation(`/tyres/${car?.id}`);
              handleClose();
              break;
            default:
              data = {};
          }
          setSubmiting(true);
        } else return;
      } catch (error) {
        console.log(error);
      }
      setSubmiting(false);
    }
  };

  const handleChangeError = (e: React.FormEvent<HTMLInputElement>, type: "run" | "tread") => {
    const value: any = +(e.target as HTMLInputElement).value;
    const lastAction = tyre?.last_action;
    setErrors((prevError: any) => ({
      ...prevError,
      [type]: [],
    }));

    if (type === "run") {
      setRun(value);
      if (lastAction && lastAction?.run > value) {
        setErrors((prevError: any) => ({
          ...prevError,
          run: "Пробег не может быть меньше предыдущего",
        }));
      }
    } else if (type === "tread") {
      setTread(value);
      if (lastAction && lastAction?.tread < value) {
        setErrors((prevError: any) => ({
          ...prevError,
          tread: "Глубина не может быть больше предыдущего",
        }));
      }
    }
  };

  const handleChangeTread = (e: any) => handleChangeError(e, "tread");
  const handleChangeRun = (e: any) => handleChangeError(e, "run");
  const handlePosition = () => {};

  if (submiting) {
    return (
      <ModalWindow
        isShow={modals.modal_add_action}
        title="Добавление шины"
        handleClose={handleClose}
        dialogClassName="modal__box-big"
      >
        <SkeletonLoading height="382px" />
      </ModalWindow>
    );
  }

  return (
    <ModalWindow
      isShow={modals.modal_add_action}
      title="Добавление события"
      handleClose={handleClose}
      dialogClassName="modal__box-big"
    >
      <form id="addTransport" onSubmit={handleSave} className="modal-content__box">
        <div>
          <p className="model-title__bold">Местоположение шины </p>
          {car?.design != null && modelId && car && car?.position_tyres && tyre?.position_tyre ? (
            <TyrePositionList
              modelId={modelId}
              defaultPositions={tyre?.position_tyre}
              trailerId={trailerId}
              wheelCount={8}
              handlePositions={handlePosition}
              tyre={tyre?.position_tyre}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          <p className="model-title__bold">Модель и размеры шин</p>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Бренд"
              readOnly={readonly}
              // length={7}
              value={tyre ? tyre.brand : brand}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setBrand((e.target as HTMLInputElement).value)
              }
              // errors={errors.brand}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Модель"
              readOnly={readonly}
              // length={3}
              value={tyre ? tyre.model : model}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setModel((e.target as HTMLInputElement).value)
              }
              // errors={errors.model}
            />
          </div>
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              classes={["modal-title__third"]}
              title="Размер"
              readOnly={readonly}
              // length={3}
              value={tyre ? tyre.size : size}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setSize((e.target as HTMLInputElement).value)
              }
              errors={errors.size}
            />
            <ModalInput
              titleClass="modal-password_title"
              classes={["modal-title__third"]}
              title="Пробег"
              readOnly={readonly}
              // length={3}
              value={tyre && tyre.last_action ? tyre.last_action.run : runLast}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setRunLast((e.target as HTMLInputElement).value)
              }
              errors={errors.runLast}
            />
            <ModalInput
              titleClass="modal-password_title"
              classes={["modal-title__third"]}
              title="Глубина протектора"
              readOnly={readonly}
              // length={3}
              value={tyre && tyre.last_action ? tyre.last_action.tread : tread_start}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setTreadStart((e.target as HTMLInputElement).value)
              }
              errors={errors.runLast}
            />
          </div>
          <h4 className="point__title">Вид</h4>
          <SelectInput
            value={type}
            options={actionTypes}
            onChange={onChangeType}
            className="react-select--mini react-select--margin "
            placeholder="Выберите событие"
          />
        </div>
        {(type === "repair" || type === "store") && (
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Пробег"
              // length={12}
              name="run"
              value={run}
              onChange={handleChangeRun}
              errors={errors.run}
            />
            <ModalInput
              titleClass="modal-password_title"
              title="Глубина протектора"
              name="tread"
              value={tread}
              // onChange={(e: React.FormEvent<HTMLInputElement>) => handleChangeTread(e)}
              onChange={handleChangeTread}
              errors={errors.tread}
            />
          </div>
        )}
        {type == "moving" && (
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="ТС, на которое переместить"
              classes={["modal-title__half"]}
              value={to}
              type="number"
              // length={16}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setTo((e.target as HTMLInputElement).value)
              }
              errors={errors.to}
            />
          </div>
        )}
        {type == "store" && (
          <div className="modal__inputs-box">
            <ModalInput
              titleClass="modal-password_title"
              title="Причина"
              type="text"
              // length={32}
              value={reason}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setReason((e.target as HTMLInputElement).value)
              }
              errors={errors.reason}
            />
          </div>
        )}
        {type == "repair" && (
          <>
            <div className="modal__inputs-box">
              <ModalInput
                titleClass="modal-password_title"
                title="Вид ремонта"
                // length={3}
                name="type_of_repair"
                value={formRepair.type_of_repair}
                onChange={handleInput}
              />
              <ModalInput
                titleClass="modal-password_title"
                title="Стоимость"
                // length={3}
                name="price"
                value={formRepair.price}
                onChange={handleInput}
              />
            </div>
            <div className="modal__inputs-box">
              <div className="input-area input-area--border w100 m0">
                <label className="label">Дата производства</label>
                <CustomDatePicker
                  onChange={onDateChoose}
                  date={production_date}
                  classes="react-datepicker_big"
                />
              </div>
              <ModalInput
                titleClass="modal-password_title"
                title="Причина ремонта"
                // length={3}
                name="reason"
                value={formRepair.reason}
                onChange={handleInput}
              />
            </div>
            <ModalTextarea
              titleClass="modal-password_title"
              title="Комментарий"
              rowsLength={2}
              name="comment"
              value={formRepair.comment}
              onChange={handleInput}
              errors={errors.comment}
            />
            <div>
              <div className={cls.ctnButtonFile}>
                <button className="modal__button" onClick={handlePick}>
                  Прикрепить файл
                </button>
                <input
                  id="myFileInput"
                  type="file"
                  ref={filePicker}
                  multiple
                  onChange={handleChangeUpload}
                  className={cls.input}
                />
              </div>
              <div className={cls.tableFile}>
                {selectFile?.map((el: any, index: any) => (
                  <div className={cls.ctnFile} key={index}>
                    <div className={cls.infoFile}>
                      <img className={cls.imgFile} src={imgFile} alt="file" />
                      <div className={cls.fileName}>{truncateFilename(el.name)}</div>
                    </div>
                    {delValidation === index ? (
                      <div className={cls.modalValid}>
                        <ValidationDelete
                          onClickYes={() => handleDelete(index)}
                          onClickNo={closeValidDelete}
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                    <div onClick={() => clickDelete(index)} className={cls.basket}>
                      <img src={delNoActiv} alt="basket" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </form>

      <div className="modal-edit__button-box">
        <button form="addTransport" type="submit" className="modal__button">
          Сохранить
        </button>
      </div>
    </ModalWindow>
  );
};

export default ModalAddAction;
