import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPoint } from "../../redux/map/map_types";
import { useActions } from "../../hooks/useAction";
import { useParams } from "react-router-dom";
import Vioalations from "../../components/violations/Violations";
import NoData from "../../components/no-data/NoData";
import SkeletonLoading from "../../components/skeleton-loading/SkeletonLoading";
import CustomScroll from "react-custom-scroll";
import PointStatus from "../../components/vehicle-view/PointStatus";
import { CarState } from "redux/car/car_types";
import toast from "react-hot-toast";

const ViewCarStatusPage = () => {
  const { vehicle_id } = useParams<{ vehicle_id?: string }>();
  const { last_point, loading_point, car }: CarState = useTypedSelector((state) => state.car);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const point: null | IPoint = last_point;

  const { getLastPointServer, getEventsLast, changePoint } = useActions();
  const { events_last } = useTypedSelector((state) => state.events);

  useEffect(() => {
    if (car && car.id) getEventsLast(car.id);
    if (!car || !car.device_id || !vehicle_id || car.id !== +vehicle_id) return;
    const init = async () => {
      const response: any = await getLastPointServer(car.device_id, true);

      if (response.status !== 200 && response.status !== 201) {
        toast.error(response.data.message);
      } else {
        setIsDataLoaded(true);
      }
    };

    init();
    const interval = setInterval(() => {
      init();
    }, 15000);

    return () => clearInterval(interval);
  }, [car?.device_id, car?.id]);

  useEffect(() => {
    return () => {
      changePoint(null);
    };
  }, []);

  if (loading_point || car === null)
    return (
      <div className="view__inner">
        <SkeletonLoading height="200px" />
      </div>
    );

  if (!point) return <NoData />;

  return (
    <CustomScroll allowOuterScroll={true} heightRelativeToParent="100%" flex="1">
      <div className="view__inner">
        <PointStatus car={car} point={point} />

        <Vioalations
          events={events_last}
          text={"Последние нарушения"}
          classPage={true}
          eventsLength={events_last.length}
        />
      </div>
    </CustomScroll>
  );
};

export default ViewCarStatusPage;
