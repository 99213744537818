import { useActions } from "hooks/useAction";
import { useTypedSelector } from "hooks/useTypedSelector";
import { TyresState } from "redux/tyres/tyres_types";
import Loader from "components/loader/Loader";
import TyreTitle from "components/tyres/TyreTitle";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TyreHead from "components/tyres/TyreHead";
import TyreDate from "components/tyres/TyreDate";
import moment from "moment";
import TyreHeader from "components/tyres/TyreHeader";
import ActionLine from "components/actions/ActionLine";
import { ActionsState } from "redux/actions/actions_types";
import { CarState } from "redux/car/car_types";
import cls from "./style/TyresPage.module.scss";
import GeneralTyrePos from "components/constructor/placementTyre/GeneralTyrePos";
import ReportCount from "components/reports/report-components/ReportCount";
import { ConstructorState } from "redux/constructor/constructor_types";
import TyrePositionList from "components/constructor/placementTyre/TyrePositionList";
import { event } from "cypress/types/jquery";

const TyrePage = () => {
  const [eventData, setEventData] = useState<any>([]);
  const [tyres, setTyres] = useState<any>();
  const [cars, setCars] = useState<any>();
  const { tyre_id } = useParams<{ tyre_id?: string }>();
  const [actionLength, setActionLength] = useState<any>(0);
  const { car }: CarState = useTypedSelector((state) => state.car);
  const constructor: ConstructorState = useTypedSelector((state) => state.design);
  const { modelId, trailerId, positions } = constructor;
  const {
    getTyre,
    toggleModal,
    getActionsPeriod,
    visibleAction,
    resetActionPeriod,
    getAxelActionsAll,
  } = useActions();
  const today = moment(new Date()).format("YYYY-MM-DD");
  const { loading_tyres, tyre, tyres_by_car }: TyresState = useTypedSelector(
    (state) => state.tyres
  );
  const { actions, loading, visible, type, reset }: ActionsState = useTypedSelector(
    (state) => state.actions
  );
  const titles = [
    "ДАТА",
    "СОБЫТИЕ",
    "ТРАНСПОРТ",
    "ШИНА",
    "МЕСТОПОЛОЖЕНИЕ",
    "ПРОБЕГ",
    "ГЛУБИНА ПРОТЕКТОРА",
  ];
  const countRun = tyres_by_car?.map((el) => el.last_action?.run);
  const runTotal = countRun?.reduce((acc: any, el) => acc + el, 0);
  // const countAction = actions?.actions.filter((el) => el.tyre_id === tyre?.id);
  const perodActions = async (action: any) => {
    return await setEventData(action);
  };

  function filterByType(array: any, type: any, id: any) {
    const numericId = parseInt(id, 10);
    if (type === "all") {
      return array?.actions;
    }
    return array?.actions.filter((el: any) => el.type === type && el.tyre_id === numericId);
  }

  const init = async () => {
    if (!tyre_id) return;
    await Promise.all([getTyre(+tyre_id), getActionsPeriod(today, today)]);
    visibleAction(false);
    setActionLength(actions?.actions?.length);
  };
  const updateData = () => {
    setEventData(filterByType(actions, type, tyre_id));
    setTyres(tyre);
    setCars(car);
    if (!reset) {
      resetActionPeriod(false);
    }
  };

  useEffect(() => {
    init();
  }, [tyre_id]);

  useEffect(() => {
    updateData();
  }, [type, actions, tyre, car, reset, tyre_id]);

  useEffect(() => {
    if (reset) {
      setEventData(undefined);
    }
  }, [reset]);

  const handleClick = () => {
    toggleModal({ toggle: true, modal: "modal_update_tyre" });
  };

  const handleClickAction = () => {
    toggleModal({ toggle: true, modal: "modal_add_action" });
  };

  function tyreActionsItems() {
    return actions?.actions && car && tyre && actions?.actions.length >= 0 ? (
      <>
        <TyreHeader titles={titles} classes="tyre_table_box-8" />
        {actions?.actions.map((action: any) => (
          <ActionLine
            key={action.id}
            date={action.updated_at}
            type={action.type}
            car_title={car.title}
            tyre_model={tyre.model}
            tyre_brand={tyre.brand}
            run={action.run}
            tread={action.tread}
            modelID={car?.design?.model_id}
            trailerId={car?.design?.trailer_id}
            tyre={action?.tyre}
          />
        ))}
      </>
    ) : visible === false ? (
      <div className="border-text">Ничего не найдено</div>
    ) : (
      <div className="border-text">Выберите вид события</div>
    );
  }
  const handlePosition = () => {};

  return (
    <>
      {loading_tyres ? (
        <Loader />
      ) : (
        <>
          <div className="report_head">
            <TyreTitle
              title="Шина"
              subtitle="информация"
              btnTitle="редактировать общую инормацию"
              handleClick={handleClick}
            />
            <div className={cls.ctnInfoTyre}>
              <div className={cls.reportCount}>
                <ReportCount
                  count={eventData?.length && eventData?.length}
                  title1="количество"
                  title2="событий"
                />
              </div>
              <div className={cls.modelContainer}>
                {car?.design != null && modelId && car && tyre && tyre?.position_tyre ? (
                  <TyrePositionList
                    modelId={modelId}
                    defaultPositions={tyre?.position_tyre}
                    trailerId={trailerId}
                    wheelCount={8}
                    handlePositions={handlePosition}
                    tyre={tyre?.position_tyre}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className={cls.reportCount}>
                <ReportCount count={tyre?.last_action?.run} title1="Общий" title2="пробег" />
              </div>
            </div>
          </div>
          {tyre && <TyreHead {...tyre} />}
          <div className="report_head">
            {/* <div className="report_head"> */}
            <div className={cls.ctnTittleAction}>
              <TyreTitle
                title="События"
                subtitle="за период"
                btnTitle="добавить событие"
                handleClick={handleClickAction}
              />
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TyreDate
                  from={
                    actions && actions.from ? actions.from : moment(new Date()).format("YYYY-MM-DD")
                  }
                  to={actions?.to}
                />
                {visible && visible === true ? (
                  eventData != undefined && eventData?.length > 0 && cars && tyres ? (
                    <>
                      <TyreHeader titles={titles} classes="tyre_table_box-8" />
                      {eventData.map((action: any) => (
                        <ActionLine
                          key={action.id}
                          date={action.updated_at}
                          type={action.type}
                          car_title={cars.title}
                          tyre_model={tyres.model}
                          tyre_brand={tyres.brand}
                          run={action.run}
                          tread={action.tread}
                          modelID={modelId}
                          trailerId={trailerId}
                          tyre={tyres}
                        />
                      ))}
                    </>
                  ) : eventData?.length === 0 ? (
                    <div className="border-text">Ничего не найдено</div>
                  ) : (
                    <>
                      {tyreActionsItems()}
                      {actions?.actions?.length === 0 && (
                        <div className="border-text">По данному периоду ничего не найдено</div>
                      )}
                    </>
                  )
                ) : (
                  <div className="border-text">Выберите период</div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TyrePage;
